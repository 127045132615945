import InUsePic from './BA_Alice_Bayer_03_1.jpg';

const InUse=()=>{
    return (<>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'75px',flexDirection:'column'}}>
        <p style={{marginTop:'13px',fontSize:'30px'}}>
        for requests contact: a.bayer@gmx.ch<br/> 
find more projects on: alicebayer.cargo.site</p>
        <img src={InUsePic} style={{width:'82%'}}/>
       
        
        </div>
        
    </>);

}

export default InUse;