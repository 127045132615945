import { Link, useLocation } from 'react-router-dom';
import useSound from 'use-sound';
import soundoff from './sound_off.svg'
import soundon from './sound_on.svg'
import mp3 from './MeltingPot.mp3'
import { useState } from 'react';
const Header = () =>{
    
    const location=useLocation()

    console.log(location.pathname);
    return (
        <div className="Header" style={{position:'fixed',top:'0px',display:'flex',alignItems:"center",justifyContent:'space-between',borderBottom:location.pathname!=="/"?'2px solid #000000':'0px'}}>
              <div className="title">
                <Link to ="/" style={{textDecoration:'none'}}>MELTING POT </Link>
                {location.pathname!=="/" && <span className="subtitle">
                    {location.pathname ==="/all-over-the-world" && "all over the World"}
                    {location.pathname ==="/in-the-news" && "in the News"}
                    {location.pathname ==="/receipts" && "Receipts"}
                    {location.pathname ==="/myth" && "the Myth"}
                    {location.pathname ==="/the-set" && "the Set"}
                    {location.pathname ==="/echo" && "Echo"}
                </span>}       
            </div>
            
            <div className="title" style={{float:'right'}} style={{display:'flex',alignItems:'center',marginRight:'30px'}}>
            {location.pathname==="/" && <Link to="/the-set"  style={{textDecoration:'none',color:'#E0C47A'}}>SET</Link>}
                <BoopButton />
            </div>
      </div>
    )
}

const BoopButton = () => {
  const [playState,setPlaystate] = useState(false);
  const [play,{stop,pause}] = useSound(mp3,{loop:true});

  const handleClick=() => {
    if(playState){
      pause();
    }
    else play();
    setPlaystate(!playState);
  }

  return <img src={playState ? soundon : soundoff} style={{height:'40px',marginLeft:'30px',width:'40px'}} onClick={() => handleClick()}/>
};


export default Header;