import { Link } from 'react-router-dom';

import background from './chasstube.jpeg';
import gabelanimate from './gabel_animation.gif';
import caquelon from './caquelon_animation.gif';
import schale from './schale_animation.gif';
import schalelang from './schale_lang_animation.gif';
import teller from './teller_animation.gif';
import schaleklein from './schale_klein_animation.gif';
import './flickity.css';
import './App.css';

const Home = () => {
  const onLoad = () => {
    const elem = document.querySelector('#home');
    const elemWidth = elem.scrollWidth;
    const vpWidth = window.innerWidth;
    const scrollValue = (elemWidth - vpWidth) / 2 - 150;
    elem.scrollTo(scrollValue, 0);
  };

  return (
    <div style={{ top: '58px', bottom: '0px',overflow:window.orientation !== undefined ? 'scroll':'none', marginTop: '60px', left: '-50%' }} id="home">
      <div style={{ position: 'relative',lineHeight:0 }}>
        <img
          src={background}
          alt="background"
          style={{ height: 'calc(100vh - 60px)', minWidth: '100%', objectFit: 'cover', position: 'relative'}}
          onLoad={onLoad}
          id="background_img"
        />
        <div style={{ overflow: 'hidden'}}>
          <Link to="/in-use" style={{ position: 'absolute', overflow: 'hidden' }} id="gabel_link" >
            <img src={gabelanimate} className="img" id="gabel" />
          </Link>
          <Link to="/all-over-the-world" style={{ position: 'absolute', overflow: 'hidden' }} id="schale_link">
            <img src={schale} className="img" id="schale"/>
          </Link>
          <Link to="/myth" style={{ position: 'absolute' }} id="caquelon_link">
            <img src={caquelon} className="img" id="caquelon"/>
          </Link>
          <Link to="/echo" style={{ position: 'absolute'  }} id="schalelang_link">
            <img src={schalelang} className="img" id="schale"/>
          </Link>
          <Link to="/receipts" style={{ position: 'absolute'}} id="teller_link">
            <img src={teller} className="img"  id="teller"/>
          </Link>

          <Link to="/in-the-news" style={{ position: 'absolute' }} id="schaleklein_link">
            <img src={schaleklein} className="img"  id="schaleklein"/>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;